<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <h3>
        {{ translate('title') }}
      </h3>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        :hover="isAdmin"
        show-index
        border
        rounded
        class="mb-4"
        @row-click="handleRowClick"
      >
        <template #cell-receiptDate="{ rowData: { receiptDate } }">
          <p>{{ formatDate(receiptDate) }}</p>
        </template>
        <template #cell-receipt="{ rowData: { document } }">
          <Button type="link" class="p-0" @click.stop="selectedDocumentId = document.id">
            <p>{{ $t(`document.exports.schema.type.shortName.${document.type}`) }} {{ document.documentNumber }}</p>
          </Button>
        </template>
        <template #cell-amount="{ rowData: { amount } }">
          <p>{{ formatMoney(amount) }}</p>
        </template>
        <template #cell-paymentMethod="{ rowData: { paymentMethod } }">
          <p>{{ $t(`payment.exports.paymentMethods.shortName.${paymentMethod}`) }}</p>
        </template>
      </Table>
    </div>
    <DocumentModal
      v-if="selectedDocumentId"
      visible
      :document-id="selectedDocumentId"
      @close="selectedDocumentId = null"
    />
    <PaymentDifferencesMatchingModal
      v-if="selectedSupplier && selectedSupplierPaymentDifferences.length"
      :supplier="selectedSupplier"
      :payment-differences="selectedSupplierPaymentDifferences"
      @close="handlePaymentDifferenceMatchModalClose"
      @paymentDifferenceMatchSuccess="handlePaymentsMatchSuccess"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { flatten, uniq } from 'ramda';

import { Table, Button, TableLoadingSkeleton } from '@/modules/core';
import { DocumentModal } from '@/modules/documentModal';
import { useUser } from '@/modules/auth';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../../tools/formatters';
import PaymentDifferencesMatchingModal from './PaymentDifferencesMatchingModal';

const TABLE_HEADER = {
  RECEIPT_DATE: 'receiptDate',
  SUPPLIER: 'supplier',
  RECEIPT: 'receipt',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
};

export default {
  components: { PaymentDifferencesMatchingModal, Table, Button, DocumentModal, TableLoadingSkeleton },
  props: {
    customer: { type: Object, required: true },
    suppliers: { type: Array, required: true },
    paymentDifferences: { type: Array, required: true },
  },
  setup(props) {
    const { isAdmin } = useUser();
    const docIds = computed(() =>
      flatten(
        uniq(
          props.paymentDifferences.map(({ supplierPayment: { eventReferences } }) =>
            eventReferences.map(({ documentId }) => documentId)
          )
        )
      )
    );

    const { documents, loading } = useDocumentsByIds(
      computed(() => ({
        businessId: props.customer.id,
        ids: docIds.value,
      }))
    );

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      isAdmin,
      missingCustomerPayments: computed(() =>
        props.paymentDifferences.map(({ supplierPayment }) => ({
          ...supplierPayment,
          eventReferences: supplierPayment.eventReferences.map((eventReference) => ({
            ...eventReference,
            document: documents.value.find((doc) => doc.id === eventReference.documentId),
          })),
        }))
      ),
      loading,
      formatMoney,
      formatDate,
      selectedDocumentId: ref(null),
      selectedSupplier: ref(null),
      selectedSupplierPaymentDifferences: ref([]),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate(`table.headers.${TABLE_HEADER.RECEIPT_DATE}`),
          key: TABLE_HEADER.RECEIPT_DATE,
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.SUPPLIER}`),
          key: TABLE_HEADER.SUPPLIER,
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.RECEIPT}`),
          key: TABLE_HEADER.RECEIPT,
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.AMOUNT}`),
          key: TABLE_HEADER.AMOUNT,
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
        },
      ];
    },
    tableData() {
      return !this.loading
        ? this.missingCustomerPayments.map(({ date, supplierId, eventReferences, amount, isRefund, transactions }) => {
            const supplier = this.suppliers.find(({ id }) => id === supplierId);
            const paymentMethod = transactions[0].paymentMethod;

            return {
              [TABLE_HEADER.RECEIPT_DATE]: date,
              [TABLE_HEADER.SUPPLIER]: supplier?.name ?? '-',
              [TABLE_HEADER.AMOUNT]: amount * (isRefund ? -1 : 1),
              [TABLE_HEADER.PAYMENT_METHOD]: paymentMethod,
              document: eventReferences?.[0].document,
            };
          })
        : [];
    },
  },
  methods: {
    translate(key) {
      return this.$t(`payment.paymentDifferences.${key}`);
    },
    handleRowClick(index) {
      if (!this.isAdmin) return;
      const selectedPaymentDifference = this.missingCustomerPayments[index];
      const supplierId = selectedPaymentDifference.supplierId;
      this.selectedSupplier = this.suppliers.find(({ id }) => id === supplierId);

      this.selectedSupplierPaymentDifferences = this.missingCustomerPayments.filter(
        (supplierPayment) => supplierPayment.supplierId === supplierId
      );
    },
    handlePaymentDifferenceMatchModalClose() {
      this.selectedSupplierPaymentDifferences = [];
      this.selectedSupplier = null;
    },
    handlePaymentsMatchSuccess() {
      this.selectedSupplierPaymentDifferences = [];
      this.selectedSupplier = null;
      this.$emit('paymentDifferenceMatchSuccess');
    },
  },
};
</script>
