<template>
  <el-dialog
    visible
    append-to-body
    :before-close="close"
    :show-close="false"
    custom-class="rounded payment-differences-matching-modal"
    @mousewheel="closeActions()"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 border-bottom">
        <h2 class="text-typography-primary">
          {{ translate('title') }}
        </h2>
        <Button type="text" class="p-0 text-typography-primary" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="h-100 py-5 px-4 d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between">
        <div class="ms-2 w-100">
          <h3 class="text-typography-primary fw-bold mb-5">{{ supplier.name }}</h3>
          <Table
            v-loading="loading"
            :data="supplierTableData"
            :columns="supplierTableColumns"
            :cell-class="handleCellClass"
            custom-class="payment-table"
            show-index
            border
            rounded
            :row-selection.sync="supplierTableRowSelection"
            :select-all-toggle="false"
            @row-click="() => null"
          >
            <template #cell-receiptDate="{ rowData: { receiptDate } }">
              <p>{{ formatDate(receiptDate) }}</p>
            </template>
            <template #cell-receipt="{ rowData: { document } }">
              <TruncatedText>
                <Button type="link" class="p-0" @click.stop="selectedDocumentId = document.id">
                  <p>
                    {{ $t(`document.exports.schema.type.shortName.${document.type}`) }} {{ document.documentNumber }}
                  </p>
                </Button>
              </TruncatedText>
            </template>
            <template #cell-amount="{ rowData: { amount } }">
              <p>{{ formatMoney(amount) }}</p>
            </template>
            <template #cell-paymentMethod="{ rowData: { paymentMethod, receiptDate, document, amount }, rowIndex }">
              <div class="d-flex justify-content-between">
                <template v-if="paymentMethod">
                  <p>{{ $t(`payment.exports.paymentMethods.shortName.${paymentMethod}`) }}</p>
                </template>
                <el-dropdown
                  trigger="click"
                  @command="handleAction($event, receiptDate, supplier.name, document, amount, rowIndex)"
                  @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
                >
                  <Button
                    :id="`actions-row-${rowIndex}`"
                    type="text"
                    class="p-0 actions-btn text-typography-primary"
                    :class="{ active: activeActions === rowIndex }"
                    @click.stop="handleOuterClick"
                  >
                    <KebabIcon />
                  </Button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="ACTION_TYPES.CREATE_PAYMENT_EVENT">
                      <p class="text-typography-primary gap-1">
                        <MergedEventsIcon />
                        {{
                          $t('payment.paymentDifferences.matchingModal.supplierTable.actions.createPaymentEvent', {
                            customer: customer.name,
                          })
                        }}
                      </p>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </Table>
        </div>
        <div class="w-100">
          <h3 class="text-typography-primary fw-bold mb-5">{{ customer.name }}</h3>
          <Table
            v-loading="loading"
            :data="customerTableData"
            :columns="customerTableColumns"
            show-index
            border
            rounded
            :row-selection.sync="customerTableRowSelection"
            :select-all-toggle="false"
            @row-click="() => null"
          >
            <template #cell-paymentDate="{ rowData: { paymentDate } }">
              <p>{{ formatDate(paymentDate) }}</p>
            </template>
            <template #cell-amount="{ rowData: { amount } }">
              <p>{{ formatMoney(amount) }}</p>
            </template>
            <template #cell-paymentMethod="{ rowData: { paymentMethod } }">
              <template v-if="paymentMethod">
                <p>{{ $t(`payment.exports.paymentMethods.shortName.${paymentMethod}`) }}</p>
              </template>
            </template>
          </Table>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <div class="border rounded d-flex">
          <div class="px-7 py-5 d-flex flex-column align-items-center">
            <small>{{ translate('footer.receiptTotal') }}</small>
            <h2 class="text-typography-primary">
              {{ formatMoney(selectedReceiptAmount) }}
            </h2>
          </div>
          <div class="px-7 bg-outline">
            <div class="px-5">
              <div class="px-2"></div>
            </div>
          </div>
          <div class="px-7 py-5 d-flex flex-column align-items-center">
            <small>{{ translate('footer.paymentsTotal') }}</small>
            <h2 class="text-typography-primary">
              {{ formatMoney(selectedPaymentsAmount) }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <DocumentModal
      v-if="selectedDocumentId"
      visible
      :document-id="selectedDocumentId"
      @close="selectedDocumentId = null"
    />
    <MatchPaymentDisparityModal
      v-if="matchPaymentDisparityModalData"
      :payment="matchPaymentDisparityModalData.payment"
      @close="matchPaymentDisparityModalData = null"
    />
    <template #footer>
      <div class="d-flex justify-content-end pb-4 px-4">
        <div class="d-flex align-items-end">
          <Button type="secondary" size="medium" @click="close">
            {{ $t('commons.cancel') }}
          </Button>
          <Button size="medium" @click="match">
            {{ translate('match') }}
          </Button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import Big from 'big.js';

import { Table, Button, TruncatedText } from '@/modules/core';
import { CloseIcon, MergedEventsIcon, KebabIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useBusinessById } from '@/modules/business/compositions/business';

import { formatDate } from '../../tools/formatters';
import { usePaymentPatch } from '../../compositions';
import { usePayments } from './pastPayments/compositions/payment';

const ACTION_TYPES = {
  CREATE_PAYMENT_EVENT: 'createPaymentEvent',
};

const SUPPLIER_PAYMENT_TABLE_HEADER = {
  RECEIPT_DATE: 'receiptDate',
  RECEIPT: 'receipt',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
};

const CUSTOMER_PAYMENT_TABLE_HEADER = {
  PAYMENT_DATE: 'paymentDate',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
};

export default {
  components: {
    KebabIcon,
    MergedEventsIcon,
    Table,
    Button,
    TruncatedText,
    CloseIcon,
    DocumentModal,
    MatchPaymentDisparityModal: () => import('@/modules/payment/customer/components/MatchPaymentDisparityModal'),
  },
  props: {
    supplier: { type: Object, required: true },
    paymentDifferences: { type: Array, required: true },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const translate = (key) => $i18n.t(`payment.paymentDifferences.matchingModal.${key}`);
    const close = () => emit('close');
    const { business: customer } = useBusinessById(
      computed(() => ({ businessId: props.paymentDifferences[0].customerId }))
    );

    const { payments, loading: paymentsLoading } = usePayments(
      () => ({
        businessId: customer.value?.id,
        supplierId: props.supplier.id,
        completed: true,
      }),
      () => ({ enabled: Boolean(customer.value?.id) })
    );

    const paymentsWithoutEventReferences = computed(() =>
      payments.value.filter((payment) => !payment.eventReferences.length)
    );

    const { patchPayment, loading: patchPaymentLoading, onError } = usePaymentPatch();

    onError(() => $message.error(translate('messages.matchingFailure')));

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      translate,
      close,
      formatDate,
      formatMoney,
      patchPayment,
      supplierTableRowSelection: ref([]),
      customerTableRowSelection: ref([]),
      activeActions: ref(null),
      matchPaymentDisparityModalData: ref(null),
      selectedDocumentId: ref(null),
      paymentsWithoutEventReferences,
      loading: computed(() => paymentsLoading.value || patchPaymentLoading.value),
      customer,
      activePopover: ref(-1),
      ACTION_TYPES,
    };
  },
  computed: {
    supplierTableColumns() {
      return [
        {
          header: this.translate(`supplierTable.headers.${SUPPLIER_PAYMENT_TABLE_HEADER.RECEIPT_DATE}`),
          key: SUPPLIER_PAYMENT_TABLE_HEADER.RECEIPT_DATE,
        },
        {
          header: this.translate(`supplierTable.headers.${SUPPLIER_PAYMENT_TABLE_HEADER.RECEIPT}`),
          key: SUPPLIER_PAYMENT_TABLE_HEADER.RECEIPT,
          width: '130px',
        },
        {
          header: this.translate(`supplierTable.headers.${SUPPLIER_PAYMENT_TABLE_HEADER.AMOUNT}`),
          key: SUPPLIER_PAYMENT_TABLE_HEADER.AMOUNT,
        },
        {
          header: this.translate(`supplierTable.headers.${SUPPLIER_PAYMENT_TABLE_HEADER.PAYMENT_METHOD}`),
          key: SUPPLIER_PAYMENT_TABLE_HEADER.PAYMENT_METHOD,
        },
      ];
    },
    supplierTableData() {
      return this.paymentDifferences.map(({ date, eventReferences, amount, isRefund, transactions }, index) => {
        const paymentMethod = transactions?.[0].paymentMethod;

        return {
          [SUPPLIER_PAYMENT_TABLE_HEADER.RECEIPT_DATE]: date,
          [SUPPLIER_PAYMENT_TABLE_HEADER.AMOUNT]: amount * (isRefund ? -1 : 1),
          [SUPPLIER_PAYMENT_TABLE_HEADER.PAYMENT_METHOD]: paymentMethod,
          document: eventReferences?.[0].document,
          selectionDisabled: !!this.supplierTableRowSelection.length && this.supplierTableRowSelection[0] !== index,
        };
      });
    },
    customerTableColumns() {
      return [
        {
          header: this.translate(`customerTable.headers.${CUSTOMER_PAYMENT_TABLE_HEADER.PAYMENT_DATE}`),
          key: CUSTOMER_PAYMENT_TABLE_HEADER.PAYMENT_DATE,
        },
        {
          header: this.translate(`customerTable.headers.${CUSTOMER_PAYMENT_TABLE_HEADER.AMOUNT}`),
          key: CUSTOMER_PAYMENT_TABLE_HEADER.AMOUNT,
        },
        {
          header: this.translate(`customerTable.headers.${CUSTOMER_PAYMENT_TABLE_HEADER.PAYMENT_METHOD}`),
          key: CUSTOMER_PAYMENT_TABLE_HEADER.PAYMENT_METHOD,
        },
      ];
    },
    customerTableData() {
      return this.paymentsWithoutEventReferences.map(({ date, amount, isRefund, transactions }) => {
        const paymentMethod = transactions?.[0]?.paymentMethod;

        return {
          [CUSTOMER_PAYMENT_TABLE_HEADER.PAYMENT_DATE]: date,
          [CUSTOMER_PAYMENT_TABLE_HEADER.AMOUNT]: amount * (isRefund ? -1 : 1),
          [CUSTOMER_PAYMENT_TABLE_HEADER.PAYMENT_METHOD]: paymentMethod,
        };
      });
    },
    selectedReceiptPayments() {
      return this.supplierTableRowSelection.map((index) => this.paymentDifferences[index]);
    },
    selectedReceiptAmount() {
      return this.selectedReceiptPayments.reduce((sum, { amount, isRefund }) => sum + amount * (isRefund ? -1 : 1), 0);
    },
    selectedPayments() {
      return this.customerTableRowSelection.map((index) => this.paymentsWithoutEventReferences[index]);
    },
    selectedPaymentsAmount() {
      return (
        this.selectedPayments
          .reduce((sum, { amount, isRefund }) => sum.add(amount * (isRefund ? -1 : 1)), new Big(0))
          .toNumber() ?? 0
      );
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOuterClick);
  },
  methods: {
    handleCellClass(rowIndex) {
      if (this.activeActions === rowIndex) return 'bg-secondary';
    },
    handleOuterClick() {
      this.activePopover = -1;
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    closeActions() {
      if (this.activeActions !== null) {
        document.getElementById(`actions-row-${this.activeActions}`).click();
        this.activeActions = null;
      }
      if (this.activeMissingReconciliationTaskPopover !== -1) this.activeMissingReconciliationTaskPopover = -1;
    },
    async match() {
      if (this.selectedReceiptAmount !== this.selectedPaymentsAmount) {
        this.$message.error(this.translate('messages.matchingAmountsError'));
        return;
      }

      if (!this.selectedPaymentsAmount || !this.selectedReceiptAmount) {
        this.$message.error(this.translate('messages.paymentsNotSelected'));
        return;
      }

      const selectedReceiptPayment = this.selectedReceiptPayments[0];
      const eventReference = {
        reference: selectedReceiptPayment.eventReferences[0].reference,
        documentId: selectedReceiptPayment.eventReferences[0].documentId,
      };

      await Promise.all(
        this.selectedPayments.map((payment) =>
          this.patchPayment({
            id: payment.id,
            patchParams: { eventReferences: [eventReference] },
          })
        )
      );

      this.$message.success(this.translate('messages.matchingSuccess'));
      this.$emit('paymentDifferenceMatchSuccess');
    },
    handleAction(command, receiptDate, supplierName, document, amount, rowIndex) {
      switch (command) {
        case ACTION_TYPES.CREATE_PAYMENT_EVENT:
          this.matchPaymentDisparityModalData = {
            payment: {
              receiptDate,
              supplierName,
              document,
              amount,
              id: this.paymentDifferences[rowIndex]['id'],
            },
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .payment-differences-matching-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }

  display: grid;
  grid-template-rows: 62px 1fr 50px;
  width: 77rem;
}

.bg-outline {
  background-color: $outline;
}

.payment-table {
  .actions-btn {
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  .hover-btn {
    &:hover {
      background: $secondary;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}
</style>
